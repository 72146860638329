import React from "react";
import styled from "styled-components";

import droneDeliveryIllustration from "./../images/illustration/drone_delivery.svg";
import careersIllustration from "./../images/illustration/careers.svg";
import dubaiStockImage from "./../images/dubai.jpg";
import ictConsultingIllustration from "./../images/illustration/ict_consulting.svg";
import managedServiceSolutionsIllustration from "./../images/illustration/managed_service_solutions.svg";
import enterpriseSolutionsIllustration from "./../images/illustration/enterprise_solutions.svg";
import dataReportsIllustration from "./../images/illustration/data_reports.svg";
import complexProblemsIllustration from "./../images/illustration/complex_problems.svg";


const IllustrationBlockContainer = styled.section`
    height: 500px;
    width: 100%;
    background-color: ${props => props.background};
    position: relative;

    span {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        height: 550px;
        padding: 30px 0;
    }
`;

const IllustrationBlockWrapper = styled.div`
    height: 100%;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    flex-wrap: wrap;
    flex-direction: ${props => props.direction || `initial`};

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        width: 85%;
        flex-direction: initial;
    }
`;

const Illustration = styled.div`
    width: 50%;
    height: 70%;
    background: url('${props => props.illustration}');
    background-size: ${props => props.type === `image` ? `cover` : `contain`};;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${props => props.type === `image` ? `3px` : `0`};

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        height: 50%;
        width: 100%;
    }
`;

const TextBlock = styled.div`
    width: 40%;
    
    
    h1 {
        font-size: 2rem;
        margin: 0 0 20px 0px;
        font-weight: 500;
        font-family: 'Rufina', serif;
    }

    h2 {
        font-size: 1.8rem;
        margin: 0 0 20px 0px;
        font-weight: 500;
        font-family: 'Rufina', serif;
    }

    p {
        font-size: 1rem;
        line-height: 1.8rem;
    }

    button.center {
        margin: 0 auto;
    }

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        width: 100%;
        margin: 20px auto;

        p {
            text-align: left;
        }

        button {
            margin: 0 auto;
        }
    }
`


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const IllustrationBlock = ({children, illustration, background, type, direction, anchor}) => {
  return (
    <IllustrationBlockContainer background={background}>
        <span className="anchor" id={anchor}/>
        <IllustrationBlockWrapper direction={direction}>
            <TextBlock>
                {children}
            </TextBlock>
            <Illustration type={type} illustration={IllustrationMap[illustration]}/>
        </IllustrationBlockWrapper>
    </IllustrationBlockContainer>
  )
}

const IllustrationMap = {
    'droneDelivery': droneDeliveryIllustration,
    'careers': careersIllustration,
    'dubai': dubaiStockImage,
    'ictConsulting': ictConsultingIllustration,
    'managedServiceSolutions': managedServiceSolutionsIllustration,
    'enterpriseSolutions': enterpriseSolutionsIllustration,
    'dataReports': dataReportsIllustration,
    'complexProblems': complexProblemsIllustration
};

export default IllustrationBlock
