import React from "react";
import styled from "styled-components";
import { Link } from "gatsby"

import logo from "./../images/logo-synergistique.png";

const FooterContainer = styled.section`
    width: 100%;
    padding: 50px 0 80px 0;

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        padding: 50px 0;
        
    }
`

const FooterWrapper = styled.div`
    width: 70%;
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        width: 90%;
        
    }
`

const FooterColumn = styled.div`
    height: 100%;
    width: calc(100%/3);

    a {
        text-decoration: none;
        color: initial;
    }

    h2 {
        font-size: 1.1rem;
        margin: 25px 0;
        font-weight: 500;
        &:hover {
            color: #3a3a3a;
        }
    }

    h3 {
        font-size: 1rem;
        font-weight: 400;
        margin: 20px 0;
        &:hover {
            color: #3a3a3a;
        }
    }

    text-align: ${props => props.align || `left`};

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {

        &:not(:last-of-type) {
            width: 50%;
        }

        &:nth-of-type(1) {
            text-align: left;
        }

        &:nth-of-type(2) {
            text-align: right;
        }

        &:last-of-type {
            margin-top: 10px;
            width: 100%;
            text-align: center;
        }

    }
`

const FooterLogo = styled.div`
    background: url('${logo}');
    height: 50px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    margin-bottom: 20px;

    @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 480px) {
        background-position: center;
    }
`

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrapper>
                <FooterColumn>
                    <Link to="/services"><h2>Services</h2></Link>
                    <Link to="/services#ict-consulting"><h3>ICT Consulting</h3></Link>
                    <Link to="/services#enterprise-solutions"><h3>Enterprise Solutions</h3></Link>
                    <Link to="/services#managed-service-solutions"><h3>Managed Service Solutions</h3></Link>
                </FooterColumn>
                <FooterColumn>
                    <h2>Company</h2>
                    <Link to="/about"><h3>About</h3></Link>
                    <Link to="/careers"><h3>Careers</h3></Link>
                    <Link to="/contact"><h3>Contact</h3></Link>
                </FooterColumn>
                <FooterColumn align={`right`}>
                    <FooterLogo/>
                    <h3>© {new Date().getFullYear()}</h3>
                    <h3>Synergistic Consulting Services</h3>
                </FooterColumn>

            </FooterWrapper>

        </FooterContainer>
    )
}

export default Footer;