import React from "react"
import styled from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer";

import IllustrationBlock from "../components/illustration-block";


const TitleHolder = styled.div`
    background-color: #F9FAFE;
    width: 100%;
    text-align: center;
    margin: 0;

    h1 {
      margin: 0;
      padding: 50px 0 0 0;
      font-size: 2rem;
      font-weight: 500;
      font-family: 'Rufina', serif;
    }
`

const ServicesPage = () => (
  <Layout>
    <SEO title="Services"/>
    <TitleHolder><h1>Our Services</h1></TitleHolder>
    <IllustrationBlock anchor={`ict-consulting`} illustration={`ictConsulting`} background={`#F9FAFE`}>
        <h1>ICT Consulting</h1>
        <p> SCS provides ICT staff augmentation services hired on fixed or flexible terms and conditions. These services entail allocation of technical resources on premise or offshore. Using ICT Staff augmentation service provides a one window solution to companies who might require skills across domains and geographical boundaries. </p>
    </IllustrationBlock>
    <IllustrationBlock anchor={`enterprise-solutions`} direction={`row-reverse`} illustration={`enterpriseSolutions`} background={`#F9FAFE`}>
      <h1>Enterprise Solutions</h1>
        <p>SCS provides end to end services for Enterprise Solutions across various set of on premise and on cloud platforms. We leverage our core capabilities, proven methodology, tools, accelerators and best practices for addressing today’s key business challenges. With our right blend of solution, approach and domain expertise, we enable customers to drive maximum business value.</p>
    </IllustrationBlock>
    <IllustrationBlock anchor={`managed-service-solutions`} illustration={`managedServiceSolutions`} background={`#F9FAFE`}>
    <h1>Managed Service Solutions</h1>
      <p>SCS provides the best-in-class underlying infrastructure required to run and deliver Managed Services to multiple users in the ecosystem of businesses. A good understanding of the increasingly complex applications allows SCS to build a strong underlying infrastructure, optimize their use and manage complex operations using IT and required SLAs.</p>
    </IllustrationBlock>
    
    <Footer/>
  </Layout>
)

export default ServicesPage
